<template>
  <v-row justify="center">
    <v-dialog :value="true" max-width="600" @input="$emit('closeDialog')">
      <v-card class="pa-8">
        <!-- init step -->
        <v-row class="justify-end">
          <v-btn icon color="primary" @click="$emit('closeDialog')">
            <v-icon v-text="'close'" />
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-1 px-3">
            <h2 class="d-block mb-4" v-text="title" />
          </v-col>
          <v-col cols="12" class="py-1 px-3">
            <v-text-field
              v-model="form.reason" outlined dense :label="`${$t('project.competition.bans.reason')} ${$t('common.maxCharacters', { size: 100})}`"
              :error-messages="getFieldErrors('reason')" @blur="$v.form.reason.$touch()"
            />
          </v-col>
          <v-col cols="12" class="py-1 px-3">
            <v-select
              v-model="form.matchCount" :items="matchCountItems" outlined dense :label="$t('project.competition.bans.matchCount')"
              :error-messages="getFieldErrors('matchCount')" append-icon="mdi-chevron-down"
            />
          </v-col>
        </v-row>
        <v-card-actions class="pa-0">
          <v-row>
            <v-col cols="12" class="py-1 px-3 d-sm-flex justify-sm-center">
              <v-btn color="primary" class="w-100 w-sm-auto" @click="save" v-text="$t('common.save')" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { required, numeric, maxLength } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'EditBanTypeDialog',
  mixins: [formUtils],
  props: {
    organizationId: { type: String, default: null },
    projectId: { type: String, default: null },
    banType: { type: Object, default: null },
  },
  data: () => ({ form: {} }),
  computed: {
    isNewBanType: ({ banType }) => !banType,
    title() {
      return this.isNewBanType ? this.$t('project.competition.bans.newBan')
        : this.$t('project.competition.bans.editBan')
    },
    matchCountItems: () => [...Array(10)].map((_, i) => i + 1),
  },
  validations: { form: { reason: { required, maxLength: maxLength(100) }, matchCount: { required, numeric } } },
  created() {
    this.form = {
      reason: this.banType?.reason ?? null,
      matchCount: this.banType?.matchCount ?? null,
    }
  },
  methods: {
    async save() {
      if (!this.isFormValid()) return
      const { organizationId, projectId, banType } = this
      if (this.isNewBanType) {
        await this.runAsync(() => this.$store.dispatch('competition/createBanType', { organizationId, projectId, data: this.form }))
      } else {
        await this.runAsync(() => this.$store.dispatch('competition/updateBanType', { organizationId, projectId, banTypeId: banType.id, data: this.form }))
      }
      this.$emit('closeDialog')
    },
  },
}
</script>
